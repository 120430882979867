import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const CountdownTopBar = () => {
  const targetDate = new Date("2025-01-21T23:59:00"); // Target date and time
  const [timeRemaining, setTimeRemaining] = useState(getTimeUntilTarget());
const navigate = useNavigate();
  useEffect(() => {
    const timer = setInterval(() => {
      setTimeRemaining(getTimeUntilTarget());
    }, 1000); // Update every second

    return () => clearInterval(timer); // Cleanup timer on component unmount
  }, []);

  const openStakeToken = () => {
    navigate("/staking-promo");
  };

  function getTimeUntilTarget() {
    const now = new Date();
    const timeDiff = targetDate - now; // Difference in milliseconds

    if (timeDiff <= 0) {
      // If the target date is reached or passed, set countdown to zero
      return { days: 0, hours: 0, minutes: 0, seconds: 0 };
    }

    const seconds = Math.floor((timeDiff / 1000) % 60);
    const minutes = Math.floor((timeDiff / 1000 / 60) % 60);
    const hours = Math.floor((timeDiff / 1000 / 60 / 60) % 24);
    const days = Math.floor(timeDiff / 1000 / 60 / 60 / 24);

    return { days, hours, minutes, seconds };
  }

  return (
    <div className="countdown-top-bar">
      <a style={{color:"#FFFFFF"}} onClick={openStakeToken} target="_blank">STAKIN PROMOTION 120% APY</a>
    </div>
  );
};

export default CountdownTopBar;
